<template>
  <b-modal id="form-jurnal">
    <!-- <b-form-group label="Pilih Akun">
      >
      <b-form-select v-model="form2.id_akun" :options="id_akun" id="v-id_akun" name="id_akun" />
    </b-form-group> -->
    <b-form-group label="Kas">
      <b-form-input :value="currentKas ? currentKas.nama : ''" disabled></b-form-input>
    </b-form-group>
    <b-form-group label="Tanggal & Waktu" label-for="v-tanggal">
      <flat-pickr v-model="form2.tanggal" class="form-control" :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" />
    </b-form-group>
    <!-- Keterangan -->
    <b-form-group label="Keterangan" label-for="v-keterangan">
      <b-form-input v-model="form2.keterangan" id="v-keterangan" placeholder="Isi keterangan" />
    </b-form-group>
    <!-- Debit -->
    <b-form-group label="Debit" label-for="v-debit">
      <b-form-input v-model="form2.debit" id="v-debit" placeholder="Isi "
        @keyup="form2.debit = formatRupiah(form2.debit)" />
    </b-form-group>
    <!-- Kredit -->
    <!-- <b-form-group label="Kredit" label-for="v-kredit">
      <b-form-input v-model="form2.kredit" id="v-kredit"
        placeholder="Isi Kredit" @keyup="form2.kredit = formatRupiah(form2.kredit)" />
    </b-form-group> -->


    <!-- submit and reset -->
    <template #modal-footer>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"
        class="mr-1" @click.prevent="submitJurnal">
        Tambah Kas
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormRadio,
  BButton,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    flatPickr,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BForm,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BFormRadio,
    BCardText,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data: () => ({
    form2: {
      id_akun: null,
      id_kas: null,
      tanggal: null,
      modul: "kas",
      debit: 0,
      kredit: 0,
      keterangan: "-",
      // saldo: null,
    },
  }),
  computed: {
    kasOptions() {
      return this.$store.state.kas.kasOptions
    },
    currentKas() {
      return this.$store.state.kas.currentKas
    }
  },
  methods: {
    submitJurnal() {

      const payload2 = this.form2
      payload2.debit = this.unFormatRupiah(payload2.debit)
      payload2.kredit = this.unFormatRupiah(payload2.kredit)

      this.$emit('submitJurnal', payload2)
    }
  }
};
</script>
