var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "form-jurnal"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "type": "submit",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.submitJurnal($event);
            }
          }
        }, [_vm._v(" Tambah Kas ")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kas"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.currentKas ? _vm.currentKas.nama : '',
      "disabled": ""
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tanggal & Waktu",
      "label-for": "v-tanggal"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      }
    },
    model: {
      value: _vm.form2.tanggal,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "tanggal", $$v);
      },
      expression: "form2.tanggal"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Keterangan",
      "label-for": "v-keterangan"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-keterangan",
      "placeholder": "Isi keterangan"
    },
    model: {
      value: _vm.form2.keterangan,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "keterangan", $$v);
      },
      expression: "form2.keterangan"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Debit",
      "label-for": "v-debit"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-debit",
      "placeholder": "Isi "
    },
    on: {
      "keyup": function keyup($event) {
        _vm.form2.debit = _vm.formatRupiah(_vm.form2.debit);
      }
    },
    model: {
      value: _vm.form2.debit,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "debit", $$v);
      },
      expression: "form2.debit"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }